import React from "react";
import { Switch as DomSwitch, Route } from "react-router-dom";
import DisplayCourse from "./DisplayCourse";
import EditCourse from "./EditCourse";
import { Switch, Row } from "antd";
import { connect } from "react-redux";
import { setEditing } from "../ducks";

class Course extends React.Component {
  render() {
    const {
      history,
      match: {
        params: { _id }
      },
      course
    } = this.props;
    const { isEditing, setEditing } = this.props;
    return (
      <React.Fragment>
        <div className="pl-4 pr-4">
          <Row
            justify="space-between"
            type="flex"
            className="mb-3 bg-white pl-4 pr-4 pt-2 pb-2"
          >
            <h3 className="title is-3 mt-3"> {course.title}</h3>
            <div
              className="mt-3"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "start"
              }}
            >
              <Switch
                checked={isEditing}
                onChange={() => {
                  setEditing(!isEditing);
                  if (!isEditing) {
                    history.push(`/courses/${_id}/edit`);
                  } else {
                    history.push(`/courses/${_id}/display`);
                  }
                }}
              />
            </div>
          </Row>
        </div>
        <DomSwitch>
          <Route exact path="/courses/:_id/display" component={DisplayCourse} />
          <Route exact path="/courses/:_id/edit" component={EditCourse} />
        </DomSwitch>
      </React.Fragment>
    );
  }
}
const mapStateToProps = state => ({
  isEditing: state.courses.isEditing,
  course: state.courses.course
});

export default connect(
  mapStateToProps,
  { setEditing }
)(Course);
