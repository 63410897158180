import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import LoginRoute from "./common/LoginRoute";
import PrivateRoute from "./common/PrivateRoute";
import CourseRoutes from "./routes/courses";
import ChapterRoutes from "./routes/chapter";
import LessonRoutes from "./routes/lesson";
import ArticleRoutes from "./routes/article";
import TimelineRoutes from "./routes/timeline";
import TagsRoutes from "./routes/tags";
import TalkWithPrawateRoutes from "./routes/talkwithprawate";

import Login from "./login";

import Transactions from "./transactions";
import Sidebar from "./components/Sidebar";

import ArticleLanding from "./articles/Landing";
import UserLanding from "./users";
const AdminPages = ({ history }) => {
  return (
    <React.Fragment>
      <Sidebar history={history} />
      <div className="main">
        <Switch>
          <Route path="/courses" component={CourseRoutes} />
          <Route path="/chapter" component={ChapterRoutes} />
          <Route path="/lesson" component={LessonRoutes} />
          <Route path="/transactions" component={Transactions} />
          <Route path="/article" component={ArticleRoutes} />
          <Route path="/timeline" component={TimelineRoutes} />
          <Route path="/tags" component={TagsRoutes} />
          <Route path="/articles" component={ArticleLanding} />
          <Route path="/users" component={UserLanding} />
          <Route path="/talkwithprawate" component={TalkWithPrawateRoutes} />
          <Route exact path="/" component={RedirectToCourses} />
        </Switch>
      </div>
    </React.Fragment>
  );
};

const RedirectToCourses = () => <Redirect to="/courses" />;

class App extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <Switch>
            <LoginRoute exact path="/login" component={Login} />
            <PrivateRoute path="/" component={AdminPages} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
