import React from "react";
// import DisplayChapter from "./DisplayChapter";
import { connect } from "react-redux";
import { editChapter } from "../ducks";
import { Row, Switch } from "antd";
import AddChapterForm from "../AddChapterForm";
import { Formik } from "formik";
import Lessons from "../../lesson";
import ModalWithFormikForm from "@/components/ModalWithFormikForm";

class SingleChapter extends React.Component {
  state = {
    modalVisible: false
  };

  setModalVisible = bool => {
    this.setState({ modalVisible: bool });
  };

  render() {
    const {
      match: {
        params: { _id }
      },
      history,
      chapter
    } = this.props;
    const { modalVisible } = this.state;
    const { setModalVisible } = this;
    const { editChapter } = this.props;
    return (
      <React.Fragment>
        <div className="pl-4 pr-4">
          <Row
            justify="space-between"
            type="flex"
            className="mb-3 bg-white p-2"
          >
            <h3 className="title is-3 mt-3">
              Chapter: {chapter.order}. {chapter.title}{" "}
            </h3>
            <div
              className="mt-3"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "start"
              }}
            >
              <Switch
                checked={modalVisible}
                onChange={() => {
                  setModalVisible(!modalVisible);
                }}
              />
            </div>
          </Row>
          <Lessons chapter_id={_id} history={history} />
          <Formik
            initialValues={{ title: chapter.title, order: chapter.order }}
            onSubmit={values => {
              editChapter(values, _id, () => setModalVisible(false));
            }}
          >
            {({ handleSubmit, isSubmitting }) => (
              <ModalWithFormikForm
                title="Edit Chapter"
                closeModal={() => setModalVisible(false)}
                handleSubmit={() => {
                  handleSubmit();
                }}
                isSubmitting={isSubmitting}
                isVisible={modalVisible}
              >
                <AddChapterForm />
              </ModalWithFormikForm>
            )}
          </Formik>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  chapter: state.chapters.chapter
});

export default connect(
  mapStateToProps,
  { editChapter }
)(SingleChapter);
