import React from "react";
import { connect } from "react-redux";
import { patchTalkWithPrawateArticle } from "./ducks";
import { Card, Row, Col, Switch, Button, Icon } from "antd";
import { Formik, Form, Field } from "formik";

// import noImage from "../assets/no-image.png";
import { eliminateUndefinedValuesFromObject } from "../common/helpers";

import UploadFileButton from "../components/UploadFileButton";
import AntDesignInputWithFormik from "../components/AntDesignInputWithFormik";
import AntDesignTextAreaWithFormik from "../components/AntDesignTextAreaWithFormik";
import TagsInputWithFormik from "../components/TagsInputWithFormik";
import { showSuccessMessage } from "../common/Modals";

class EditGeneralInformation extends React.Component {
  state = {
    title: "",
    introduction: "",
    html: "",
    src_path: "",
    is_published: false,
    tags: []
  };
  componentDidMount() {
    const {
      title,
      introduction,
      html,
      src_path,
      is_published,
      tags
    } = this.props.article;
    this.setState({
      title,
      introduction,
      html,
      src_path,
      is_published,
      tags
    });
  }
  render() {
    const {
      title,
      introduction,
      html,
      src_path,
      is_published,
      tags
    } = this.state;
    const { full_tags, patchTalkWithPrawateArticle, history } = this.props;
    return (
      <div className="pl-4 pr-4">
        <h3 className="is-3 mt-3"> Talk With Prawate: {title}</h3>
        <Row justify="center" type="flex">
          <Col span={20} sm={24} className="mb-13">
            <Formik
              initialValues={{
                title,
                introduction,
                html,
                src_path,
                is_published,
                tags
              }}
              enableReinitialize={true}
              onSubmit={async (values, { setSubmitting }) => {
                setSubmitting(true);
                const body = eliminateUndefinedValuesFromObject(values);
                await patchTalkWithPrawateArticle(
                  body,
                  this.props.match.params._id
                );
                setSubmitting(false);
                showSuccessMessage("Success", "Update was a success");
              }}
            >
              {({ values, setFieldValue, handleSubmit }) => (
                <Card>
                  <Form>
                    <Field
                      name="title"
                      component={AntDesignInputWithFormik}
                      placeholder="E.g. รู้จักกับโรคซึมเศร้า"
                      label="Title"
                    />
                    <Field
                      name="introduction"
                      placeholder="E.g. โรคซึมเศร้า คือ ..."
                      label="Introduction"
                      component={AntDesignTextAreaWithFormik}
                    />
                    <Field
                      name="tags"
                      label="Tags"
                      component={TagsInputWithFormik}
                      tags={full_tags}
                    />
                    <UploadFileButton
                      label="Article Thumbnail"
                      _id={this.props.match.params._id}
                      model="talkwithprawate"
                      size="small"
                    />
                    <Row className="mb-3">
                      <Col span={6}>
                        <p
                          style={{
                            marginTop: 6,
                            marginBottom: 0,
                            textAlign: "right"
                          }}
                        >
                          <strong> Publication </strong>
                        </p>
                      </Col>
                      <Col span={18}>
                        <Switch
                          style={{ marginLeft: 8 }}
                          checked={values.is_published}
                          onChange={() =>
                            setFieldValue("is_published", !values.is_published)
                          }
                        />
                      </Col>
                    </Row>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <Button type="primary" onClick={handleSubmit}>
                        Save
                      </Button>
                    </div>
                  </Form>
                </Card>
              )}
            </Formik>
            <div
              style={{
                marginTop: 24,
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Button
                type="primary"
                onClick={() =>
                  history.push(
                    `/talkwithprawate/${this.props.match.params._id}/edit`
                  )
                }
              >
                <Icon type="edit" /> Edit Article
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  article: state.talkWithPrawate.activeArticle,
  full_tags: state.tags.tags
});

const mapDispatchToProps = {
  patchTalkWithPrawateArticle
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditGeneralInformation);
