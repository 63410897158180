import React from "react";
import { Formik } from "formik";
import EditCourseForm from "./EditCourseForm";
import { editCourse, setEditing } from "../ducks";
import { Card, Row, Col } from "antd";
import { connect } from "react-redux";

class EditCourse extends React.Component {
  state = {
    isCoursePublished: this.props.course.isPublished
  };
  render() {
    const {
      course: { title, recommendation_video_url, price_1yr, price_life_time },
      editCourse,
      match: {
        params: { _id }
      },
      history,
      setEditing
    } = this.props;
    const { isCoursePublished } = this.state;
    return (
      <div className="pl-4 pr-4">
        <Row justify="center" type="flex">
          <Col span={20} md={20} sm={24} className="mb-13">
            <Formik
              initialValues={{
                title,
                recommendation_video_url,
                price_1yr,
                price_life_time
              }}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true);
                values.isPublished = isCoursePublished;
                this.setState({ isCoursePublished: isCoursePublished });
                editCourse(values, _id, () => {
                  setEditing(false);
                  history.push(`/courses/${_id}/display`);
                });
              }}
            >
              {({ isSubmitting, handleSubmit }) => (
                <Card>
                  <EditCourseForm
                    isCoursePublished={isCoursePublished}
                    toggleCoursePublished={() =>
                      this.setState({ isCoursePublished: !isCoursePublished })
                    }
                    model="course"
                    _id={_id}
                    isSubmitting={isSubmitting}
                    handleSubmit={handleSubmit}
                  />
                </Card>
              )}
            </Formik>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  course: state.courses.course
});

export default connect(
  mapStateToProps,
  { editCourse, setEditing }
)(EditCourse);
