import React, { useState, useEffect } from "react";
import { Card, Row, Col, Button, Icon } from "antd";
import { Formik, Form, Field } from "formik";
import { connect } from "react-redux";
import {
  fetchTalkWithPrawateArticles,
  postTalkWithPrawateArticle,
  setActiveArticle
} from "./ducks";
import { fetchTags } from "../tag/ducks";
import { showRequestErrorModal } from "../common/Error";

import noImage from "../assets/no-image.png";

import ModalWithFormikForm from "../components/ModalWithFormikForm";
import AntDesignInputWithFormik from "../components/AntDesignInputWithFormik";
import AntDesignTextAreaWithFormik from "../components/AntDesignTextAreaWithFormik";
import TagsInputWithFormik from "../components/TagsInputWithFormik";
import { showSuccessMessage } from "../common/Modals";

const FORM_INITIAL_VALUES = { title: "", introduction: "", tags: [] };

const TalkWithPrawateArticles = ({
  articles,
  tags,
  fetchTags,
  fetchTalkWithPrawateArticles,
  postTalkWithPrawateArticle,
  setActiveArticle,
  history
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  useEffect(
    () => {
      async function fetch() {
        try {
          await Promise.all([fetchTalkWithPrawateArticles(), fetchTags()]);
        } catch (error) {
          showRequestErrorModal(error);
        }
      }
      fetch();
    },
    //eslint-disable-next-line
    []
  );
  return (
    <div className="pl-4 pr-4">
      <Row
        justify="space-between"
        type="flex"
        className="mb-3 bg-white pl-4 pr-4 pt-2 pb-2"
      >
        <h3 className="is-3 mt-3"> Talk with Prawate Articles</h3>
        <Button
          type="primary"
          className="mt-3"
          onClick={() => setIsModalVisible(true)}
        >
          <Icon type="plus" />
          Add
        </Button>
      </Row>
      <Row justify="center" type="flex" className="mb-3" gutter={12}>
        {articles.map(({ src_path, title, _id, introduction }) => (
          <Col span={4} md={4} sm={8} xs={20} className="mb-3 mr-3" key={_id}>
            <Card
              hoverable={true}
              onClick={() => {
                setActiveArticle(_id);
                history.push(`/talkwithprawate/${_id}`);
              }}
              cover={
                <img
                  alt={title}
                  src={src_path === undefined ? noImage : src_path}
                />
              }
            >
              <Card.Meta title={title} description={introduction} />
            </Card>
          </Col>
        ))}
      </Row>
      <Formik
        initialValues={FORM_INITIAL_VALUES}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            await postTalkWithPrawateArticle(values);
            setSubmitting(false);
            resetForm(FORM_INITIAL_VALUES);
            setIsModalVisible(false);
            showSuccessMessage("Success", "New article was created");
          } catch (error) {
            showRequestErrorModal(error);
          }
        }}
      >
        {({ isSubmitting, handleSubmit, resetForm }) => (
          <ModalWithFormikForm
            title="Add TalkWithPrawateArticle"
            closeModal={() => {
              setIsModalVisible(false);
              resetForm(FORM_INITIAL_VALUES);
            }}
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            isVisible={isModalVisible}
          >
            <Form>
              <Field
                name="title"
                component={AntDesignInputWithFormik}
                placeholder="E.g. รู้จักกับโรคซึมเศร้า"
                label="Title"
              />
              <Field
                name="introduction"
                placeholder="E.g. โรคซึมเศร้า คือ ..."
                label="Introduction"
                component={AntDesignTextAreaWithFormik}
              />
              <Field
                name="tags"
                label="Tags"
                component={TagsInputWithFormik}
                tags={tags}
              />
            </Form>
          </ModalWithFormikForm>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = state => ({
  articles: state.talkWithPrawate.articles,
  tags: state.tags.tags
});

const mapDispatchToProps = {
  fetchTalkWithPrawateArticles,
  postTalkWithPrawateArticle,
  fetchTags,
  setActiveArticle
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TalkWithPrawateArticles);
