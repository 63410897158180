import Axios from "axios";
import { handleHTTPError } from "../common/http-error-handling";
const SET_TIMELINE_ARTICLE = "SET_TIMELINE_ARTICLE";

export const fetchTimelineArticle = errorCallback => async dispatch => {
  try {
    const res = await Axios.get("/timeline_article");
    dispatch(setTimelineArticle(res.data.article));
  } catch (error) {
    handleHTTPError(error, errorCallback);
  }
};

export const setTimelineArticle = article => ({
  type: SET_TIMELINE_ARTICLE,
  payload: article
});

const initialState = {
  article: { _id: null, html: null }
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_TIMELINE_ARTICLE: {
      return {
        ...state,
        article: payload
      };
    }
    default:
      return state;
  }
};
