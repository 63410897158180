import React from "react";
import { Modal } from "antd";
const { confirm, success } = Modal;

export function showSuccessMessage(title, content, callback) {
  success({
    title: title,
    content: <p> {content} </p>,
    onOk() {
      if (callback) {
        callback();
      }
    }
  });
}

export async function showConfirmation(title, content, onOk) {
  confirm({
    title: title,
    content: content,
    onOk() {
      onOk();
      success({
        title: "Success",
        content: <p> Your operation was completed successfully</p>
      });
    }
  });
}
