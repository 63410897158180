import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Button, Row } from "antd";
import { editLessonById } from "./ducks";

const LessonArticlePreview = ({ lesson, match, history, editLessonById }) => {
  useEffect(() => {
    if (!lesson) return history.push("/");
    if (!lesson.article) {
      return history.push("/chapter/" + lesson.chapter_id);
    }
    document.getElementById("article_preview").innerHTML = lesson.article;
    // eslint-disable-next-line
  }, [lesson]);
  return (
    <React.Fragment>
      <Row
        justify="space-between"
        type="flex"
        className="mb-3 bg-white pl-4 pr-4 pt-2 pb-2"
      >
        <h3 className="title is-3 mt-3">
          Q/A Lesson.{lesson.order}: {lesson.title}{" "}
        </h3>
        <div
          className="mt-3"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start"
          }}
        >
          <Button
            type="primary"
            onClick={() => history.push("/lesson/article/" + match.params._id)}
          >
            Edit
          </Button>
        </div>
      </Row>
      <div
        id="article_preview"
        style={{
          maxWidth: 1000,
          margin: "0 auto",
          // backgroundColor: "white",
          fontSize: 21
          // textAlign: "center"
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center"
        }}
      >
        <Button
          type="primary"
          onClick={async () => {
            await editLessonById(lesson, match.params._id, () =>
              history.push("/chapter/" + lesson.chapter_id)
            );
          }}
        >
          Save
        </Button>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  lesson: state.lessons.activeLesson
});

export default connect(
  mapStateToProps,
  { editLessonById }
)(LessonArticlePreview);
