import React from "react";
import { Card, Row, Col, Button, Icon } from "antd";
import { Formik } from "formik";
import { connect } from "react-redux";
import AddAndEditLessonForm from "./AddAndEditLessonForm";
import ModalWithFormikForm from "../components/ModalWithFormikForm";
import {
  fetchLessons,
  postLesson,
  setActiveLessonById,
  editLessonById,
} from "./ducks";
import ReactPlayer from "react-player";

class Lesson extends React.Component {
  state = {
    modalVisible: false,
    editingLessonModalVisible: false,
  };
  setModalVisible = (bool) => this.setState({ modalVisible: bool });
  setEditingLessonModalVisible = (bool) =>
    this.setState({ editingLessonModalVisible: bool });
  componentDidMount() {
    const { fetchLessons, chapter_id } = this.props;
    fetchLessons(chapter_id);
  }

  render() {
    const {
      lessons,
      postLesson,
      chapter_id,
      lesson,
      setActiveLessonById,
      editLessonById,
      history,
    } = this.props;
    const { modalVisible, editingLessonModalVisible } = this.state;
    const { setModalVisible, setEditingLessonModalVisible } = this;
    return (
      <React.Fragment>
        <Row justify="space-between" type="flex" className="mb-3 bg-white p-2">
          <h3 className="is-3 mt-3"> Lessons</h3>
          <Button
            type="primary"
            className="mt-3"
            onClick={() => setModalVisible(true)}
          >
            <Icon type="plus" />
            Add
          </Button>
        </Row>
        <Row justify="center" type="flex" className="mb-3">
          {lessons.map(({ order, title, src_path, _id, type }) => {
            return (
              <Col
                span={6}
                md={6}
                sm={6}
                xs={24}
                className="mb-3 mr-3"
                key={_id}
              >
                <Card
                  cover={
                    src_path && (
                      <ReactPlayer
                        url={src_path}
                        controls={true}
                        width={362}
                        height={192}
                      />
                    )
                  }
                >
                  <Card.Meta
                    title={
                      <Row justify="space-between" type="flex">
                        <p> {`${order}. ${title}`}</p>
                        <div>
                          <Button
                            type="default"
                            onClick={() => {
                              setActiveLessonById(_id);
                              setEditingLessonModalVisible(true);
                            }}
                          >
                            {" "}
                            Edit{" "}
                          </Button>
                          <Button
                            style={{ marginLeft: 8 }}
                            type="dashed"
                            onClick={() => {
                              setActiveLessonById(_id);
                              history.push("/lesson/article/" + _id);
                            }}
                          >
                            {" "}
                            Q/A
                          </Button>
                        </div>
                      </Row>
                    }
                  />
                </Card>
              </Col>
            );
          })}
        </Row>
        <Formik
          onSubmit={(values, { resetForm }) => {
            values.chapter_id = chapter_id;
            postLesson(values);
            setModalVisible(false);
            resetForm({});
          }}
        >
          {({ handleSubmit, resetForm, isSubmitting }) => (
            <ModalWithFormikForm
              title="Add Lesson"
              closeModal={() => {
                setModalVisible(false);
                resetForm({});
              }}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              isVisible={modalVisible}
            >
              <AddAndEditLessonForm />
            </ModalWithFormikForm>
          )}
        </Formik>
        <Formik
          onSubmit={(values) => {
            editLessonById(values, lesson._id, () =>
              setEditingLessonModalVisible(false)
            );
          }}
          enableReinitialize={true}
          initialValues={{
            title: lesson.title,
            order: "" + lesson.order,
            src_path: lesson.src_path,
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <ModalWithFormikForm
              title="Edit Lesson"
              closeModal={() => {
                setEditingLessonModalVisible(false);
              }}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              isVisible={editingLessonModalVisible}
            >
              <AddAndEditLessonForm />
            </ModalWithFormikForm>
          )}
        </Formik>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  lessons: state.lessons.lessons,
  lesson: state.lessons.activeLesson,
});

export default connect(mapStateToProps, {
  fetchLessons,
  postLesson,
  setActiveLessonById,
  editLessonById,
})(Lesson);
