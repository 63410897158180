import Axios from "axios";

const SET_COURSES = "SET_COURSES";
const SET_COURSE = "SET_COURSE";
const ADD_COURSE = "ADD COURSE";
const SET_ACTIVE_COURSE = "SET ACTIVE COURSE";
const SET_EDITING = "SET_EDITING";

export const fetchCourses = () => async dispatch => {
  try {
    const {
      data: { courses }
    } = await Axios.get("/courses");
    dispatch(setCourses(courses));
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const postCourse = body => async dispatch => {
  try {
    const {
      data: { course }
    } = await Axios.post("/add-course", body);
    dispatch(addCourse(course));
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const editCourse = (body, _id, callback) => async dispatch => {
  try {
    const {
      data: { course }
    } = await Axios.put(`/course/${_id}`, body);
    callback();
    dispatch(setCourse(course, _id));
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const setEditing = bool => {
  return {
    type: SET_EDITING,
    payload: bool
  };
};

export const addCourse = course => {
  return {
    type: ADD_COURSE,
    payload: course
  };
};

export const setCourses = courses => {
  return {
    type: SET_COURSES,
    payload: courses
  };
};

export const setCourse = (course, _id) => {
  return {
    type: SET_COURSE,
    payload: { course, _id }
  };
};

export const setActiveCourse = _id => {
  return {
    type: SET_ACTIVE_COURSE,
    payload: _id
  };
};

const initialState = {
  courses: [],
  course: {},
  isEditing: false
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_EDITING: {
      return {
        ...state,
        isEditing: payload
      };
    }
    case SET_COURSES: {
      return { ...state, courses: payload };
    }
    case SET_COURSE: {
      const { _id, course } = payload;
      const index = state.courses.findIndex(course => course._id === _id);
      let updatedCourses = [...state.courses];
      updatedCourses[index] = course;
      return {
        ...state,
        course: course,
        courses: updatedCourses
      };
    }
    case ADD_COURSE: {
      return {
        ...state,
        courses: [...state.courses, payload]
      };
    }
    case SET_ACTIVE_COURSE: {
      return {
        ...state,
        course: state.courses.find(course => course._id === payload)
      };
    }
    default:
      return state;
  }
};
