import React from "react";
import { Switch, Route } from "react-router-dom";
import LessonArticle from "../lesson/LessonArticle";
import LessonArticlePreview from "../lesson/ArticlePreview";

const LessonRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/lesson/article/:_id/preview"
        component={LessonArticlePreview}
      />
      <Route exact path="/lesson/article/:_id" component={LessonArticle} />
    </Switch>
  );
};

export default LessonRoutes;
