import React, { useState, useEffect } from "react";
import { Card, Row, Col, Button, Icon } from "antd";
import { Formik } from "formik";
import { connect } from "react-redux";
import { fetchCourses, postCourse } from "./ducks";

import noImage from "../assets/no-image.png";

import AddCourseForm from "./AddCourseForm";
import ModalWithFormikForm from "../components/ModalWithFormikForm";

const Courses = ({ fetchCourses, courses, postCourse, history, match }) => {
  const [modalVisible, setModalVisible] = useState(false);
  useEffect(
    function() {
      fetchCourses();
    },
    [fetchCourses]
  );
  return (
    <div className="pl-4 pr-4">
      <Row
        justify="space-between"
        type="flex"
        className="mb-3 bg-white pl-4 pr-4 pt-2 pb-2"
      >
        <h3 className="is-3 mt-3"> Courses</h3>
        <Button
          type="primary"
          className="mt-3"
          onClick={() => setModalVisible(true)}
        >
          <Icon type="plus" />
          Add
        </Button>
      </Row>
      <Row justify="center" type="flex" className="mb-3" gutter={12}>
        {courses.map(({ description, src_path, isPublished, title, _id }) => (
          <Col span={4} md={4} sm={8} xs={20} className="mb-3 mr-3" key={_id}>
            <Card
              hoverable={true}
              onClick={() => history.push(`${match.url}/${_id}/display`)}
              cover={
                <img
                  alt={title}
                  src={src_path === undefined ? noImage : src_path}
                />
              }
            >
              <Card.Meta title={title} description={description} />
            </Card>
          </Col>
        ))}
      </Row>

      <Formik
        initialValues={{ title: "", price_1yr: 490, price_life_time: 790 }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          postCourse(values);
          setModalVisible(false);
          setSubmitting(false);
          resetForm({ price_1yr: 490, price_life_time: 790 });
        }}
      >
        {({ isSubmitting, handleSubmit, resetForm }) => (
          <ModalWithFormikForm
            title="Add Course"
            closeModal={() => {
              setModalVisible(false);
              resetForm({ price_1yr: 490, price_life_time: 790 });
            }}
            handleSubmit={() => {
              handleSubmit();
            }}
            isSubmitting={isSubmitting}
            isVisible={modalVisible}
          >
            <AddCourseForm />
          </ModalWithFormikForm>
        )}
      </Formik>
    </div>
  );
};

const mapStateToProps = state => ({
  courses: state.courses.courses
});

export default connect(
  mapStateToProps,
  { fetchCourses, postCourse }
)(Courses);
