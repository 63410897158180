import Axios from "axios";
import { removeAuthToken } from "./auth";
import configureStore from "./store";
import { setAuthentication } from "../login/ducks";
import { END_POINT } from "./httpconstants";
const { store } = configureStore();

export default () => {
  Axios.defaults.baseURL = END_POINT;
  Axios.defaults.headers.post["Content-Type"] = "application/json";
  Axios.interceptors.response.use(null, function(error) {
    if (!error.response || error.response.status === 403) {
      removeAuthToken();
      store.dispatch(setAuthentication(false));
    }
    return Promise.reject(error);
  });
};
