import React from "react";
import { Card, Row, Col, Statistic } from "antd";
import ReactPlayer from "react-player";
import noImage from "@/assets/no-image.png";
import Chapters from "../../chapter";
const CourseDisplay = ({
  title,
  recommendation_video_url,
  src_path,
  description,
  isPublished,
  price_1yr,
  price_life_time,
  date_created,
  _id
}) => (
  <Card
    cover={
      <img
        style={{ margin: "auto", maxWidth: 400 }}
        alt={title}
        src={src_path === undefined ? noImage : src_path}
      />
    }
  >
    <Row justify="center" type="flex">
      <Col span={20} md={20} sm={24} className="mb-3">
        <Statistic title="Title" value={title} />
      </Col>
      <Col span={20} md={20} sm={24} className="mb-3">
        <Statistic title="Description" value={description} />
      </Col>
      <Col span={10} md={10} sm={24} className="mb-3">
        <Statistic title="Price for 1 year" value={price_1yr} />
      </Col>
      <Col span={10} md={10} sm={24} className="mb-3">
        <Statistic title="Price for life time" value={price_life_time} />
      </Col>
      <Col span={20} md={20} sm={24} className="mb-3">
        <div className="ant-statistic">
          <div className="ant-statistic-title">Recommendation video</div>
        </div>

        {recommendation_video_url && (
          <ReactPlayer
            url={recommendation_video_url}
            controls={true}
            style={{
              marginBottom: 16,
              marginTop: 16
            }}
          />
        )}
      </Col>
      <Col span={10} md={10} sm={24} className="mb-3">
        <Statistic title="Date created" value={date_created} />{" "}
      </Col>
      <Col span={10} md={10} sm={24} className="mb-3">
        <Statistic title="Status" value={isPublished} />{" "}
      </Col>
    </Row>
    <Row>
      <Chapters _id={_id} />
    </Row>
  </Card>
);

export default CourseDisplay;
