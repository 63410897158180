import React, { useEffect } from "react";
import { Button } from "antd";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import useReactEditor from "../hooks/useReactEditor";
import { connect } from "react-redux";
import { setLesson } from "./ducks";

const LessonArticle = ({ history, lesson, setLesson }) => {
  const {
    editorState,
    onEditorStateChange,
    uploadImageCallBack
  } = useReactEditor(null);
  useEffect(() => {
    // console.log(history, "history");
    const loadHTMLToDraft = () => {
      const { article } = lesson;
      if (!article) return;
      const contentBlock = htmlToDraft(article);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        onEditorStateChange(EditorState.createWithContent(contentState));
      }
    };
    loadHTMLToDraft();
    // eslint-disable-next-line
  }, [lesson]);
  return (
    <React.Fragment>
      <div className="bg-white pl-4 pr-4 pt-2 pb-2">
        <h2 style={{ marginBottom: 0 }}>
          Q/A Lesson {lesson.order} : {lesson.title}
        </h2>
      </div>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          image: {
            uploadCallback: uploadImageCallBack,
            alt: { present: true, mandatory: false }
          }
        }}
      />
      <div style={{ display: "flex", justifyContent: "center", marginTop: 12 }}>
        <Button
          type="primary"
          size="large"
          style={{ minWidth: 120 }}
          onClick={() => {
            const article = draftToHtml(
              convertToRaw(editorState.getCurrentContent())
            );
            setLesson({ ...lesson, article });
            history.push("/lesson/article/" + lesson._id + "/preview");
          }}
        >
          Next
        </Button>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  lesson: state.lessons.activeLesson
});

export default connect(
  mapStateToProps,
  { setLesson }
)(LessonArticle);
