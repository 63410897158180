import React from "react";
import { Form, Field } from "formik";
// import { Editor } from "react-draft-wysiwyg";
import UploadFileButton from "@/components/UploadFileButton";
import { Button, Row, Col, Switch } from "antd";
// import { convertToRaw } from "draft-js";
// import draftToHtml from "draftjs-to-html";

import AntDesignInputWithFormik from "../../components/AntDesignInputWithFormik";
import FormikVideoInput from "../../components/FormikVideoInput";

// description,
// onEditorStateChange,
const EditCourseForm = ({
  _id,
  model,
  isSubmitting,
  handleSubmit,
  isCoursePublished,
  toggleCoursePublished
}) => (
  <Form>
    <Field
      name="title"
      component={AntDesignInputWithFormik}
      placeholder="Title"
      label="Title"
    />
    <Field
      name="recommendation_video_url"
      component={FormikVideoInput}
      placeholder="Recommendation Video URL"
      label="Video URL"
    />
    <UploadFileButton label="Course Picture" _id={_id} model={model} />
    <Field
      name="price_1yr"
      component={AntDesignInputWithFormik}
      placeholder="Price for 1 year"
      label="1yr price"
      type="number"
    />
    <Field
      name="price_life_time"
      component={AntDesignInputWithFormik}
      placeholder="Price for life time"
      type="number"
      label="life time price"
    />
    <Row className="mb-3">
      <Col span={4}>
        <p style={{ marginTop: 6, marginBottom: 0, textAlign: "center" }}>
          <strong> Publication </strong>
        </p>
      </Col>
      <Col span={20}>
        <Switch checked={isCoursePublished} onChange={toggleCoursePublished} />
      </Col>
    </Row>
    <div style={{ float: "right" }}>
      <Button
        onClick={handleSubmit}
        disabled={isSubmitting}
        type="primary"
        size="large"
      >
        Submit
      </Button>
    </div>
  </Form>
);

export default EditCourseForm;
