import React from "react";
import { Switch, Route } from "react-router-dom";

import SingleChapter from "../chapter/SingleChapter";
import CreateOrEditChapterArticle from "../chapter/ChapterArticle";
import ChapterArticlePreview from "../chapter/ArticlePreview";

const ChapterRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/chapter/:_id/article/preview"
        component={ChapterArticlePreview}
      />
      <Route
        exact
        path="/chapter/:_id/article"
        component={CreateOrEditChapterArticle}
      />
      <Route exact path="/chapter/:_id" component={SingleChapter} />
    </Switch>
  );
};

export default ChapterRoutes;
