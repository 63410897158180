import React from "react";
import { Switch, Route } from "react-router-dom";
import TalkWithPrawateLanding from "../talkwithprawate";
import EditGeneralInformation from "../talkwithprawate/EditGeneralInformation";
import EditHTML from "../talkwithprawate/UpdateArticle";
import PreviewHTML from "../talkwithprawate/Preview";

const TalkWithPrawateRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/talkwithprawate/:_id/preview"
        component={PreviewHTML}
      />
      <Route exact path="/talkwithprawate/:_id/edit" component={EditHTML} />
      <Route
        exact
        path="/talkwithprawate/:_id"
        component={EditGeneralInformation}
      />
      <Route exact path="/talkwithprawate" component={TalkWithPrawateLanding} />
    </Switch>
  );
};

export default TalkWithPrawateRoutes;
