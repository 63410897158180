import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  fetchOnlineArticles,
  setArticle,
  fetchWorkshopArticles,
} from "./ducks";
import { List, Button } from "antd";
import { Link } from "react-router-dom";
import WorkshopArticleList from "./component/WorkshopArticleList";

const Landing = ({
  onlineArticles,
  fetchOnlineArticles,
  setArticle,
  history,
  fetchWorkshopArticles,
  workshopArticles,
}) => {
  const [online_workshop_list, setOnlineWorkshopList] = useState([]);
  const [two_day_workshop, setTwoDayWorkshopList] = useState([]);

  useEffect(
    function() {
      fetchOnlineArticles();
      fetchWorkshopArticles();
    },
    [fetchOnlineArticles, fetchWorkshopArticles]
  );

  useEffect(() => {
    const t_online_workshop_list = [];
    const t_two_day_workshop_list = [];
    for (let i = 0; i < workshopArticles.length; i++) {
      if (workshopArticles[i]._type === "1_DAY") {
        t_online_workshop_list.push(workshopArticles[i]);
      } else {
        t_two_day_workshop_list.push(workshopArticles[i]);
      }
      setOnlineWorkshopList(t_online_workshop_list);
      setTwoDayWorkshopList(t_two_day_workshop_list);
    }
  }, [workshopArticles]);
  return (
    <React.Fragment>
      <div className="mb-3 bg-white pl-4 pr-4 pt-2 pb-2">
        <h1> Articles </h1>
      </div>
      {/* <div className="bg-white">
        <List
          header={
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h4> Online Articles</h4>
              <Button
                type="primary"
                onClick={() => history.push("/article/online-editor")}
              >
                New
              </Button>
            </div>
          }
          bordered
          dataSource={onlineArticles}
          renderItem={({ title, _id, course, html, isPublished }) => (
            <List.Item key={_id}>
              <Link
                to={`/article/online/${_id}`}
                onClick={() =>
                  setArticle({ title, course, html, _id, isPublished })
                }
              >
                {title}
              </Link>
            </List.Item>
          )}
        />
      </div> */}
      <WorkshopArticleList
        workshop_type="2-day workshop"
        workshop_list={two_day_workshop}
        history={history}
        setArticle={setArticle}
      />
      <WorkshopArticleList
        workshop_type="online workshop"
        workshop_list={online_workshop_list}
        history={history}
        setArticle={setArticle}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  onlineArticles: state.articles.onlineArticles,
  workshopArticles: state.articles.workshopArticles,
});

const mapDispatchToProps = {
  fetchWorkshopArticles,
  fetchOnlineArticles,
  setArticle,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Landing);
