import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";
// import { Formik } from "formik";
// import {
//   EditorState,
//   convertToRaw,
//   convertFromHTML,
//   ContentState
// } from "draft-js";
// import draftToHtml from "draftjs-to-html";

import CourseDisplay from "./CourseDisplay";
import { setActiveCourse } from "../ducks";

const Course = ({
  course: {
    title,
    recommendation_video_url,
    src_path,
    description,
    isPublished,
    price_1yr,
    price_life_time,
    date_created
  },
  match: {
    params: { _id }
  },
  history,
  setActiveCourse
}) => {
  useEffect(
    function() {
      setActiveCourse(_id);
    },
    [setActiveCourse, _id]
  );
  return (
    <div className="pl-4 pr-4">
      <Row justify="center" type="flex">
        <Col span={20} md={20} sm={24} className="mb-3">
          <CourseDisplay
            title={title}
            description={description}
            isPublished={isPublished}
            price_1yr={price_1yr}
            price_life_time={price_life_time}
            date_created={date_created}
            recommendation_video_url={recommendation_video_url}
            src_path={src_path}
            _id={_id}
            history={history}
          />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = state => ({
  course: state.courses.course
});

export default connect(
  mapStateToProps,
  { setActiveCourse }
)(Course);
