import React, { useState } from "react";
import { Input, Card, Button } from "antd";
import { connect } from "react-redux";
import { logIn } from "./ducks";

const Login = ({ logIn, history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onSubmit = function() {
    logIn({ email, password }, history);
  };
  return (
    <div
      className="container"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh"
      }}
    >
      <div>
        <Card>
          <h3 className="display-4 text-center"> Log in </h3>
          <form onSubmit={onSubmit}>
            <Input
              placeholder="Email"
              value={email}
              onPressEnter={onSubmit}
              onChange={e => setEmail(e.target.value)}
            />
            <Input
              placeholder="Password"
              className="mt-3"
              type="password"
              onPressEnter={onSubmit}
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
            <p className="text-center mt-3">
              <Button type="submit" onClick={onSubmit}>
                Login
              </Button>
            </p>
          </form>
        </Card>
      </div>
    </div>
  );
};

export default connect(
  null,
  { logIn }
)(Login);
