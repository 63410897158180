import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logOut } from "../login/ducks";

const Sidebar = ({ logOut, history }) => {
  return (
    <div className="sidenav">
      <Link to="/courses"> Courses </Link>
      <Link to="/transactions"> Transactions </Link>
      <Link to="/articles"> Workshops </Link>
      <Link to="/timeline"> Timeline </Link>
      <Link to="/tags"> Tags </Link>
      <Link to="/talkwithprawate"> Articles</Link>
      <Link to="/users"> Users </Link>
      <button
        onClick={() => {
          logOut(history);
        }}
      >
        Log out
      </button>
    </div>
  );
};

export default connect(
  null,
  { logOut }
)(Sidebar);
