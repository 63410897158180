import React from "react";
import { Switch, Route } from "react-router-dom";
import TimelineLanding from "../timeline";
import UpdateTimeline from "../timeline/UpdateTimeline";
import TimelineArticlePreview from "../timeline/TimelineArticlePreview";

const TimelineRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/timeline/:_id/preview"
        component={TimelineArticlePreview}
      />
      <Route exact path="/timeline/:_id" component={UpdateTimeline} />
      <Route exact path="/timeline" component={TimelineLanding} />
    </Switch>
  );
};

export default TimelineRoutes;
