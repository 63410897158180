export function eliminateUndefinedValuesFromObject(obj) {
  const objClone = { ...obj };
  const keys = Object.keys(obj);
  for (let i = 0; i < keys.length; i++) {
    if (objClone[keys[i]] === undefined) {
      delete objClone[keys[i]];
    }
  }
  return objClone;
}
