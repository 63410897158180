import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Button, Row } from "antd";
import { patchTalkWithPrawateArticle } from "./ducks";
import { showRequestErrorModal } from "../common/Error";
import { showSuccessMessage } from "../common/Modals";

const Preview = ({ match, article, history, patchTalkWithPrawateArticle }) => {
  useEffect(() => {
    if (!article.html) return history.push("/talkwithprawate");
    document.getElementById("article_preview").innerHTML = article.html;
  });
  const _id = match.params._id;
  return (
    <React.Fragment>
      <Row
        justify="space-between"
        type="flex"
        className="mb-3 bg-white pl-4 pr-4 pt-2 pb-2"
      >
        <h3 className="title is-3 mt-3">{article.title}</h3>
        <div
          className="mt-3"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start"
          }}
        >
          <Button
            type="primary"
            onClick={() => history.push("/talkwithprawate/" + _id + "/edit")}
          >
            Edit
          </Button>
        </div>
      </Row>
      <div
        id="article_preview"
        style={{
          maxWidth: 1000,
          margin: "0 auto",
          fontSize: 21
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center"
        }}
      >
        <Button
          type="primary"
          onClick={async () => {
            try {
              await patchTalkWithPrawateArticle(article, _id);
              showSuccessMessage(
                "Success",
                `${article.title}'s content updated`,
                () => history.push("/talkwithprawate")
              );
            } catch (error) {
              showRequestErrorModal(error);
            }
          }}
          style={{ marginBottom: 12 }}
        >
          Save
        </Button>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  article: state.talkWithPrawate.activeArticle
});

const mapDispatchToProps = {
  patchTalkWithPrawateArticle
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Preview);
