import Axios from "axios";

const ADD_CHAPTER = "ADD CHAPTER";
const SET_COURSES = "SET COURSES";
const SET_ACTIVE_CHAPTER = "SET ACTIVE CHAPTER";
const SET_CHAPTER = "SET CHAPTER";

export const fetchChapters = _id => async dispatch => {
  try {
    const {
      data: { chapters }
    } = await Axios.get(`/chapters/${_id}`);
    dispatch(setChapters(chapters));
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const postChapter = body => async dispatch => {
  try {
    const {
      data: { chapter }
    } = await Axios.post(`/add-chapter`, body);
    dispatch(addChapter(chapter));
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const editChapter = (body, chapter_id, callback) => async dispatch => {
  try {
    const {
      data: { chapter }
    } = await Axios.put(`/chapter/${chapter_id}`, body);
    callback();
    dispatch(setChapter(chapter));
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const setChapter = chapter => {
  return {
    type: SET_CHAPTER,
    payload: chapter
  };
};

export const setChapters = chapters => {
  return {
    type: SET_COURSES,
    payload: chapters
  };
};

export const addChapter = chapter => {
  return {
    type: ADD_CHAPTER,
    payload: chapter
  };
};

export const setActiveChapter = _id => {
  return {
    type: SET_ACTIVE_CHAPTER,
    payload: _id
  };
};

const initialState = {
  chapters: [],
  chapter: {}
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_COURSES: {
      return {
        ...state,
        chapters: payload
      };
    }
    case ADD_CHAPTER: {
      return {
        ...state,
        chapters: [...state.chapters, payload]
      };
    }
    case SET_ACTIVE_CHAPTER: {
      return {
        ...state,
        chapter: state.chapters.find(chapter => chapter._id === payload)
      };
    }
    case SET_CHAPTER: {
      const { _id } = payload;
      const index = state.chapters.find(chapter => chapter._id === _id);
      const updatedChapters = [...state.chapters];
      updatedChapters[index] = payload;
      return {
        chapters: updatedChapters,
        chapter: payload
      };
    }
    default:
      return state;
  }
};
