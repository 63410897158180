import React from "react";
import { Formik } from "formik";
import { Row, Button, Icon, List } from "antd";
// import { Accordion } from "antd-mobile";
import { Link } from "react-router-dom";
import ModalWithFormikForm from "../components/ModalWithFormikForm";
import AddChapterForm from "./AddChapterForm";
import { connect } from "react-redux";
import { fetchChapters, postChapter, setActiveChapter } from "./ducks";

const Item = List.Item;

class Chapters extends React.Component {
  state = {
    modalVisible: false
  };
  componentDidMount() {
    this.props.fetchChapters(this.props._id);
  }
  setModalVisible = bool => {
    this.setState({ modalVisible: bool });
  };
  render() {
    const { modalVisible } = this.state;
    const { setModalVisible } = this;
    const { postChapter, _id, chapters, setActiveChapter } = this.props;
    console.log(chapters, "chapters");
    return (
      <div className="pl-4 pr-4">
        <Row justify="space-between" type="flex" className="mb-3">
          <h3 className="is-3 mt-3"> Chapters</h3>
          <Button
            type="primary"
            className="mt-3"
            onClick={() => setModalVisible(true)}
          >
            <Icon type="plus" />
            Add
          </Button>
        </Row>
        <List
          size="default"
          itemLayout="horizontal"
          loading={false}
          dataSource={chapters}
          renderItem={item => (
            <Item
              key={item._id}
              actions={[
                <Link
                  to={`/chapter/${item._id}/article/preview`}
                  onClick={() => setActiveChapter(item._id)}
                >
                  {" "}
                  Q/A
                </Link>
              ]}
              style={{ justifyContent: "space-between" }}
            >
              <Link
                to={`/chapter/${item._id}`}
                onClick={() => setActiveChapter(item._id)}
              >
                {item.order}. {item.title}
              </Link>
            </Item>
          )}
        />
        <Formik
          onSubmit={(values, { setSubmitting, resetForm }) => {
            values.course_id = _id;
            postChapter(values);
            setModalVisible(false);
            setSubmitting(false);
            resetForm({});
          }}
        >
          {({ isSubmitting, handleSubmit, resetForm }) => (
            <ModalWithFormikForm
              title="Add Chapter"
              closeModal={() => {
                setModalVisible(false);
                resetForm({});
              }}
              handleSubmit={() => {
                handleSubmit();
              }}
              isSubmitting={isSubmitting}
              isVisible={modalVisible}
            >
              <AddChapterForm />
            </ModalWithFormikForm>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  chapters: state.chapters.chapters
});

export default connect(
  mapStateToProps,
  { fetchChapters, postChapter, setActiveChapter }
)(Chapters);
