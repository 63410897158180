import React from "react";
import { Modal } from "antd";

export function showRequestErrorModal(error) {
  const { response } = error;
  if (!response) {
    Modal.error({
      title: "An unknown error occured",
      content: (
        <p>
          {" "}
          Please try to logout and login again to see whether the error persists
        </p>
      )
    });
  } else {
    if ((response.status > 399) & (response.status < 500)) {
      Modal.error({
        title: "An unexpected error occured",
        content: <p> {response.data.error} </p>
      });
    } else {
      Modal.error({
        title: "An unexpected error occured",
        content: <p> {JSON.stringify(response)}</p>
      });
    }
  }
}
