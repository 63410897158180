import React, { useState, useEffect } from "react";
import Axios from "axios";
import { showRequestErrorModal } from "../common/Error";
import { showConfirmation, showSuccessMessage } from "../common/Modals";
import { Row, Button, List, Icon } from "antd";
import { Formik, Form, Field } from "formik";
import ModalWithFormikForm from "../components/ModalWithFormikForm";
import AntDesignInputWithFormik from "../components/AntDesignInputWithFormik";
import { connect } from "react-redux";
import { fetchTimelineArticle } from "./ducks";

const Timeline = ({ article, fetchTimelineArticle, history }) => {
  const [timeline, setTimeline] = useState([]);
  const [isModalVisible, setisModalVisible] = useState(false);
  const [editing, setEditing] = useState({
    isEditing: false,
    initialValues: {}
  });

  useEffect(
    () => {
      async function fetchTimeline() {
        try {
          const [res] = await Promise.all([
            Axios.get("/timeline"),
            fetchTimelineArticle()
          ]);
          setTimeline(res.data.timeline_items);
        } catch (error) {
          showRequestErrorModal(error);
        }
      }
      fetchTimeline();
    },
    // eslint-disable-next-line
    []
  );

  async function editTimelineItemById(_id, values, callback) {
    try {
      await Axios.patch(`/timeline/${_id}`, values);
      const timelineClone = [...timeline];
      const index = timelineClone.findIndex(item => item._id === _id);
      timelineClone[index] = { ...values, _id };
      setTimeline(timelineClone);
      callback();
    } catch (error) {
      showRequestErrorModal(error);
    }
  }

  async function deleteTimelineItemById(_id) {
    try {
      await Axios.delete(`/timeline/${_id}`);
      const timelineClone = [...timeline];
      const index = timelineClone.findIndex(item => item._id === _id);
      timelineClone.splice(index, 1);
      setTimeline(timelineClone);
    } catch (error) {
      showRequestErrorModal(error);
    }
  }
  const _id = article._id;
  return (
    <div className="pl-4 pr-4">
      <Row
        justify="space-between"
        type="flex"
        className="mb-3 bg-white pl-4 pr-4 pt-2 pb-2"
      >
        <h3 className="is-3 mt-3"> Timeline</h3>
        <Button
          type="primary"
          className="mt-3"
          onClick={() => setisModalVisible(true)}
        >
          <Icon type="plus" />
          Add
        </Button>
      </Row>
      <div className="mb-3">
        <List
          bordered
          dataSource={timeline}
          renderItem={({ name, short_date, _id, date }) => (
            <List.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%"
                }}
              >
                <p className="mb-0">
                  <strong> {short_date} </strong> {name}
                </p>
                <p className="mb-0">
                  <Button
                    type="ghost"
                    onClick={() => {
                      setEditing(() => ({
                        isEditing: true,
                        initialValues: {
                          name,
                          short_date,
                          date: date.slice(0, 10)
                        },
                        _id: _id
                      }));
                      setisModalVisible(true);
                    }}
                  >
                    <Icon type="edit" />
                  </Button>
                  <Button
                    type="danger"
                    onClick={() => {
                      showConfirmation(
                        "Timeline deletion",
                        `An event ${name} held on ${short_date} will be deleted`,
                        async () => deleteTimelineItemById(_id)
                      );
                    }}
                    style={{ marginLeft: 8 }}
                  >
                    <Icon type="delete" />
                  </Button>
                </p>
              </div>
            </List.Item>
          )}
        />
        <Formik
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              if (editing.isEditing) {
                await editTimelineItemById(editing._id, values, () => {
                  setEditing({ isEditing: false, initialValues: {} });
                  setSubmitting(false);
                  resetForm({ name: "", short_date: "", date: "" });
                  setisModalVisible(false);
                  showSuccessMessage(
                    "Success",
                    "Your operation was completed successfully"
                  );
                });
              } else {
                const res = await Axios.post("/timeline", values);
                setTimeline(() => [...timeline, res.data.timeline_item]);
                setSubmitting(false);
                resetForm({ name: "", short_date: "", date: "" });
                setisModalVisible(false);
              }
            } catch (error) {
              showRequestErrorModal(error);
            }
          }}
          initialValues={editing.initialValues}
          enableReinitialize={true}
        >
          {({ isSubmitting, handleSubmit, resetForm }) => (
            <ModalWithFormikForm
              title={editing.isEditing ? "Edit timeline" : "Add timeline"}
              closeModal={() => {
                setisModalVisible(false);
                setEditing({ isEditing: false, initialValues: {} });
                resetForm({ name: "", short_date: "", date: "" });
              }}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              isVisible={isModalVisible}
            >
              <Form>
                <Field
                  name="name"
                  component={AntDesignInputWithFormik}
                  placeholder="E.g. Happier สุขยิ่งกว่า"
                  label="Event name"
                />
                <Field
                  name="short_date"
                  component={AntDesignInputWithFormik}
                  placeholder="E.g. 2 ก.ย."
                  label="Short date"
                />
                <Field
                  name="date"
                  component={AntDesignInputWithFormik}
                  type="date"
                  label="Event date (mm/dd/yyyy)"
                />
              </Form>
            </ModalWithFormikForm>
          )}
        </Formik>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Button type="primary" onClick={() => history.push("/timeline/" + _id)}>
          <Icon type="edit" /> Edit Timeline Article
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  article: state.timeline.article
});

const mapDispatchToProps = {
  fetchTimelineArticle
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Timeline);
