import React from "react";
import { Switch, Route } from "react-router-dom";
import Tags from "../tag";

const TagsRoutes = () => {
  return (
    <Switch>
      <Route exact path="/tags" component={Tags} />
    </Switch>
  );
};

export default TagsRoutes;
