import Axios from "axios";

const SET_ARTICLE = "SET_ARTICLE";
const SET_ONLINE_ARTICLES = "SET_ONLINE_ARTICLES";
const SET_WORKSHOP_ARTICLES = "SET_WORKSHOP_ARTICLES";

export const setArticle = value => ({
  type: SET_ARTICLE,
  payload: value
});

export const fetchWorkshopArticles = () => async dispatch => {
  try {
    const {
      data: { articles }
    } = await Axios.get("/workshop-articles");
    dispatch({
      type: SET_WORKSHOP_ARTICLES,
      payload: articles
    });
  } catch (error) {
    throw error;
  }
};
export const fetchOnlineArticles = () => async dispatch => {
  try {
    const {
      data: { articles }
    } = await Axios.get("/online-articles");
    dispatch({
      type: SET_ONLINE_ARTICLES,
      payload: articles
    });
  } catch (error) {
    throw error;
  }
};

const initialState = {
  workshopArticles: [],
  onlineArticles: [],
  activeArticle: {}
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_WORKSHOP_ARTICLES: {
      return {
        ...state,
        workshopArticles: payload
      };
    }
    case SET_ARTICLE: {
      return {
        ...state,
        activeArticle: payload
      };
    }
    case SET_ONLINE_ARTICLES: {
      return {
        ...state,
        onlineArticles: payload
      };
    }
    default:
      return state;
  }
};
