import React, { useEffect, useState } from "react";
import { Input, Button, Select } from "antd";
import { convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { setArticle } from "./ducks";
import { connect } from "react-redux";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import Axios from "axios";
import useReactEditor from "../hooks/useReactEditor";

const CreateOnlineArticle = ({ history, setArticle }) => {
  const {
    editorState,
    onEditorStateChange,
    uploadImageCallBack
  } = useReactEditor(null);
  const [title, setTitle] = useState("");
  const [courses, setCourses] = useState([]);
  const [courseId, setCourseId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  async function fetchCoursesWithNoArticle() {
    try {
      const {
        data: { coursesWithNoArticles }
      } = await Axios.get("/no-article-courses");
      setCourses(coursesWithNoArticles);
    } catch (error) {
      throw error;
    }
  }
  useEffect(() => {
    fetchCoursesWithNoArticle();
  }, []);
  return (
    <React.Fragment>
      {isLoading && <div className="loading">Loading&#8230;</div>}
      <div className="mb-3 bg-white pl-4 pr-4 pt-2 pb-2">
        <h1> Create an online article </h1>
      </div>
      <Input
        size="large"
        placeholder="Title"
        style={{ marginBottom: 12 }}
        value={title}
        onChange={e => setTitle(e.target.value)}
      />
      <Select
        placeholder="Select a course"
        style={{ minWidth: 160, marginBottom: 12 }}
        onChange={courseId => setCourseId(courseId)}
      >
        {courses.map(({ _id, title }) => (
          <Select.Option value={_id} key={_id}>
            {title}
          </Select.Option>
        ))}
      </Select>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          image: {
            uploadCallback: uploadImageCallBack,
            alt: { present: true, mandatory: false }
          }
        }}
      />
      <div style={{ display: "flex", justifyContent: "center", marginTop: 12 }}>
        <Button
          type="primary"
          size="large"
          disabled={editorState === null || courseId === ""}
          style={{ minWidth: 120 }}
          onClick={async () => {
            setIsLoading(true);
            const body = {
              html: draftToHtml(convertToRaw(editorState.getCurrentContent())),
              title,
              course: courseId,
              isPublished: false
            };
            setArticle(body);
            try {
              const res = await Axios.post("/online-article", body);
              setIsLoading(false);
              history.push("/article/online/" + res.data.article._id);
            } catch (error) {
              throw error;
            }
          }}
        >
          Save
        </Button>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  setArticle
};
export default connect(
  null,
  mapDispatchToProps
)(CreateOnlineArticle);
