import React from "react";
import { Row, Col, Input } from "antd";

const AntDesignTextAreaWithFormik = ({
  field,
  form,
  placeholder,
  label,
  ...props
}) => (
  <React.Fragment>
    <React.Fragment>
      <Row className="mb-3">
        <Col span={6}>
          <p
            style={{
              marginTop: 6,
              marginBottom: 0,
              textAlign: "right",
              marginRight: 8
            }}
          >
            {" "}
            <strong>{label} </strong>{" "}
          </p>
        </Col>
        <Col span={18}>
          <Input.TextArea {...field} {...props} placeholder={placeholder} />
        </Col>
      </Row>
    </React.Fragment>
  </React.Fragment>
);

export default AntDesignTextAreaWithFormik;
