import React, { useState, useEffect } from "react";
import { Input, Button } from "antd";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { setArticle } from "./ducks";
import { connect } from "react-redux";
import draftToHtml from "draftjs-to-html";
import Axios from "axios";
import htmlToDraft from "html-to-draftjs";
import useReactEditor from "../hooks/useReactEditor";

const EditWorkshopArticle = ({
  activeArticle: { html, title, _id, isPublished, _type },
  setArticle,
  history
}) => {
  const {
    editorState,
    onEditorStateChange,
    uploadImageCallBack
  } = useReactEditor(null);
  const [isLoading, setIsLoading] = useState(false);
  const [editingTitle, setEditingTitle] = useState(title);

  useEffect(() => {
    const loadHTMLToDraft = () => {
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        onEditorStateChange(EditorState.createWithContent(contentState));
      }
    };
    loadHTMLToDraft();
    // eslint-disable-next-line
  }, []);
  return (
    <React.Fragment>
      {isLoading && <div className="loading">Loading&#8230;</div>}
      <div className="mb-3 bg-white pl-4 pr-4 pt-2 pb-2">
        <h1>{title} </h1>
      </div>
      <Input
        size="large"
        placeholder="Title"
        style={{ marginBottom: 12 }}
        value={editingTitle}
        onChange={e => setEditingTitle(e.target.value)}
      />
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          image: {
            uploadCallback: uploadImageCallBack,
            alt: { present: true, mandatory: false }
          }
        }}
      />
      <div style={{ display: "flex", justifyContent: "center", marginTop: 12 }}>
        <Button
          type="primary"
          size="large"
          disabled={editorState === null}
          style={{ minWidth: 120 }}
          onClick={async () => {
            setIsLoading(true);
            const body = {
              html: draftToHtml(convertToRaw(editorState.getCurrentContent())),
              title: editingTitle,
              isPublished,
              _type
            };
            try {
              const res = await Axios.put(`/workshop-article/${_id}`, body);
              setArticle(res.data.article);
              setIsLoading(false);
              history.push("/article/workshop/" + res.data.article._id);
            } catch (error) {
              throw error;
            }
          }}
        >
          Save
        </Button>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  activeArticle: state.articles.activeArticle
});

const mapDispatchToProps = {
  setArticle
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditWorkshopArticle);
