import React from "react";
import { Form, Field } from "formik";

import AntDesignInputWithFormik from "../components/AntDesignInputWithFormik";
import FormikVideoInput from "../components/FormikVideoInput";

const AddCourseForm = () => (
  <Form>
    <Field
      name="title"
      component={AntDesignInputWithFormik}
      placeholder="Title"
      label="Title"
    />
    <Field
      name="recommendation_video_url"
      component={FormikVideoInput}
      placeholder="Recommendation Video URL"
      label="Video URL"
    />
    <Field
      name="price_1yr"
      component={AntDesignInputWithFormik}
      placeholder="Price for 1 year"
      label="1yr price"
      type="number"
    />
    <Field
      name="price_life_time"
      component={AntDesignInputWithFormik}
      placeholder="Price for life time"
      type="number"
      label="life time price"
    />
  </Form>
);

export default AddCourseForm;
