import React from "react";
import { Row, Col, Input } from "antd";

const AntDesignInputWithFormik = ({
  field,
  form: { touched, errors },
  placeholder,
  type,
  label,
  ...props
}) => (
  <React.Fragment>
    <Row className="mb-3">
      <Col span={6}>
        <p
          style={{
            marginTop: 6,
            marginBottom: 0,
            textAlign: "right",
            marginRight: 8
          }}
        >
          {" "}
          <strong>{label} </strong>{" "}
        </p>
      </Col>
      <Col span={18}>
        <Input
          type={type || "text"}
          {...field}
          {...props}
          placeholder={placeholder}
        />
        {touched[field.name] && errors[field.name] && (
          <div className="error">{errors[field.name]}</div>
        )}
      </Col>
    </Row>
  </React.Fragment>
);

export default AntDesignInputWithFormik;
