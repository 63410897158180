import React from "react";
import { Row, Col } from "antd";

const TagsInputWithFormik = ({
  label,
  tags,
  form: { values, setFieldValue }
}) => {
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col span={6}>
          <p
            style={{
              marginTop: 6,
              marginBottom: 0,
              textAlign: "right",
              marginRight: 8
            }}
          >
            {" "}
            <strong>{label} </strong>{" "}
          </p>
        </Col>
        <Col span={18}>
          {tags.map(({ _id, name }) => {
            const index = values.tags.findIndex(i => i === _id);
            const extraStyle =
              index === -1
                ? {
                    backgroundColor: "white",
                    border: "1px solid rgba(0,0,0,0.3)",
                    borderRadius: 4
                  }
                : {
                    backgroundColor: "#1890ff",
                    border: "1px transparent",
                    borderRadius: 4,
                    color: "white"
                  };
            return (
              <button
                type="button"
                key={_id}
                className="remove-focus"
                style={{ ...extraStyle, marginLeft: 8 }}
                onClick={() => {
                  if (index === -1) {
                    setFieldValue("tags", [...values.tags, _id]);
                  } else {
                    const tagsClone = [...values.tags];
                    tagsClone.splice(index, 1);
                    setFieldValue("tags", tagsClone);
                  }
                }}
              >
                {name}
              </button>
            );
          })}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default TagsInputWithFormik;
