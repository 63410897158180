import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import ReduxThunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import authentication from "../login/ducks";
import courses from "../course/ducks";
import chapters from "../chapter/ducks";
import lessons from "../lesson/ducks";
import articles from "../articles/ducks";
import timeline from "../timeline/ducks";
import tags from "../tag/ducks";
import talkWithPrawate from "../talkwithprawate/ducks";

const reducers = {
  authentication,
  courses,
  chapters,
  lessons,
  articles,
  timeline,
  tags,
  talkWithPrawate
};

const combinedReducers = combineReducers(reducers);

const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["authentication"]
};

const persistedReducer = persistReducer(persistConfig, combinedReducers);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
  let store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(ReduxThunk))
  );
  let persistor = persistStore(store);
  return { store, persistor };
};
