import React from "react";
import { Modal, Button } from "antd";

const ModalWithFormikForm = ({
  isSubmitting,
  handleSubmit,
  isVisible,
  title,
  closeModal,
  children
}) => (
  <Modal
    title={title}
    style={{ top: 24 }}
    onCancel={closeModal}
    visible={isVisible}
    footer={[
      <Button
        key="submit"
        type="primary"
        onClick={handleSubmit}
        disabled={isSubmitting}
      >
        Submit
      </Button>,
      <Button key="cancel" disabled={isSubmitting} onClick={closeModal}>
        Cancel
      </Button>
    ]}
  >
    {children}
  </Modal>
);

export default ModalWithFormikForm;
