import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Button, Row } from "antd";
import { setTimelineArticle } from "./ducks";
import { showRequestErrorModal } from "../common/Error";
import { showSuccessMessage } from "../common/Modals";
import Axios from "axios";

const TimelineArticlePreview = ({ article, history, setTimelineArticle }) => {
  useEffect(() => {
    if (!article.html) return history.push("/timeline");
    document.getElementById("article_preview").innerHTML = article.html;
    //eslint-disable-next-line
  }, []);
  const _id = article._id;
  return (
    <React.Fragment>
      <Row
        justify="space-between"
        type="flex"
        className="mb-3 bg-white pl-4 pr-4 pt-2 pb-2"
      >
        <h3 className="title is-3 mt-3">Timeline</h3>
        <div
          className="mt-3"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start"
          }}
        >
          <Button
            type="primary"
            onClick={() => history.push("/timeline/" + _id)}
          >
            Edit
          </Button>
        </div>
      </Row>
      <div
        id="article_preview"
        style={{
          maxWidth: 1000,
          margin: "0 auto",
          fontSize: 21
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center"
        }}
      >
        <Button
          type="primary"
          onClick={async () => {
            try {
              const res = await Axios.put(`/timeline_article/${_id}`, {
                html: article.html
              });
              console.log(res.data, "res.data");
              setTimelineArticle(res.data.article);
              showSuccessMessage("Success", "Timeline content updated", () =>
                history.push("/timeline")
              );
            } catch (error) {
              showRequestErrorModal(error);
            }
          }}
          style={{ marginBottom: 12 }}
        >
          Save
        </Button>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  article: state.timeline.article
});

const mapDispatchToProps = {
  setTimelineArticle
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimelineArticlePreview);
