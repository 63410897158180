import React, { useState, useEffect } from "react";
import { showRequestErrorModal } from "../common/Error";
import { showConfirmation, showSuccessMessage } from "../common/Modals";
import { Row, Button, List, Icon } from "antd";
import { Formik, Form, Field } from "formik";
import ModalWithFormikForm from "../components/ModalWithFormikForm";
import AntDesignInputWithFormik from "../components/AntDesignInputWithFormik";
import { connect } from "react-redux";
import { fetchTags, deleteTagById, editTagById, postTag } from "./ducks";

const Tags = ({ tags, fetchTags, postTag, deleteTagById, editTagById }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editing, setEditing] = useState({
    isEditing: false,
    initialValues: {},
    _id: null
  });

  useEffect(() => {
    async function fetch() {
      try {
        await fetchTags();
      } catch (error) {
        showRequestErrorModal(error);
      }
    }
    fetch();
    //eslint-disable-next-line
  }, []);
  return (
    <div className="pl-4 pr-4">
      <Row
        justify="space-between"
        type="flex"
        className="mb-3 bg-white pl-4 pr-4 pt-2 pb-2"
      >
        <h3 className="is-3 mt-3"> Tags</h3>
        <Button
          type="primary"
          className="mt-3"
          onClick={() => setIsModalVisible(true)}
        >
          <Icon type="plus" />
          Add
        </Button>
      </Row>
      <div className="mb-3">
        <List
          bordered
          dataSource={tags}
          renderItem={({ name, _id }) => (
            <List.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%"
                }}
              >
                <p className="mb-0">
                  <strong> {name} </strong>
                </p>
                <p className="mb-0">
                  <Button
                    type="ghost"
                    onClick={() => {
                      setEditing(() => ({
                        isEditing: true,
                        initialValues: {
                          name
                        },
                        _id: _id
                      }));
                      setIsModalVisible(true);
                    }}
                  >
                    <Icon type="edit" />
                  </Button>
                  <Button
                    type="danger"
                    onClick={() => {
                      showConfirmation(
                        "Tag Deletion",
                        `A Tag (${name}) will be deleted, this may effect TalkWithPrawate Article, Continue?`,
                        async () => deleteTagById(_id)
                      );
                    }}
                    style={{ marginLeft: 8 }}
                  >
                    <Icon type="delete" />
                  </Button>
                </p>
              </div>
            </List.Item>
          )}
        />
        <Formik
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            try {
              if (editing.isEditing) {
                await editTagById(editing._id, values);
                setEditing({ isEditing: false, initialValues: {} });
                setSubmitting(false);
                resetForm({ name: "" });
                setIsModalVisible(false);
                showSuccessMessage(
                  "Success",
                  "Targetted tag was updated successfully"
                );
              } else {
                await postTag(values);
                setSubmitting(false);
                resetForm({ name: "" });
                setIsModalVisible(false);
                showSuccessMessage(
                  "Success",
                  "New tag was created successfully"
                );
              }
            } catch (error) {
              showRequestErrorModal(error);
            }
          }}
          initialValues={editing.initialValues}
          enableReinitialize={true}
        >
          {({ isSubmitting, handleSubmit, resetForm }) => (
            <ModalWithFormikForm
              title={editing.isEditing ? "Edit a tag" : "Add a new tag"}
              closeModal={() => {
                setIsModalVisible(false);
                setEditing({ isEditing: false, initialValues: {} });
                resetForm({ name: "" });
              }}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              isVisible={isModalVisible}
            >
              <Form>
                <Field
                  name="name"
                  component={AntDesignInputWithFormik}
                  placeholder="E.g. แก้ไขปัญหาซึมเศร้า"
                  label="Name"
                />
              </Form>
            </ModalWithFormikForm>
          )}
        </Formik>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  tags: state.tags.tags
});

const mapDispatchToProps = {
  fetchTags,
  deleteTagById,
  editTagById,
  postTag
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tags);
