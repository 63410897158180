import React, { useState } from "react";
import { Input, Button, Select } from "antd";
import { convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { setArticle } from "./ducks";
import { connect } from "react-redux";
import draftToHtml from "draftjs-to-html";
import Axios from "axios";
import useReactEditor from "../hooks/useReactEditor";

const CreateWorkshopArticle = ({ history, setArticle }) => {
  const {
    editorState,
    onEditorStateChange,
    uploadImageCallBack
  } = useReactEditor(null);
  const [title, setTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [_type, setType] = useState("");
  return (
    <React.Fragment>
      {isLoading && <div className="loading">Loading&#8230;</div>}
      <div className="mb-3 bg-white pl-4 pr-4 pt-2 pb-2">
        <h1> Create a workshop article </h1>
      </div>
      <Input
        size="large"
        placeholder="Title"
        style={{ marginBottom: 12 }}
        value={title}
        onChange={e => setTitle(e.target.value)}
      />
      <Select
        placeholder="Select workshop type"
        style={{ minWidth: 160, marginBottom: 12 }}
        onChange={value => setType(value)}
      >
        <Select.Option value="1_DAY"> One day</Select.Option>
        <Select.Option value="2_DAY"> Two day</Select.Option>
      </Select>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          image: {
            uploadCallback: uploadImageCallBack,
            alt: { present: true, mandatory: false }
          }
        }}
      />
      <div style={{ display: "flex", justifyContent: "center", marginTop: 12 }}>
        <Button
          type="primary"
          size="large"
          disabled={editorState === null || _type === ""}
          style={{ minWidth: 120 }}
          onClick={async () => {
            setIsLoading(true);
            const body = {
              html: draftToHtml(convertToRaw(editorState.getCurrentContent())),
              title,
              _type,
              isPublished: false
            };
            setArticle(body);
            try {
              const res = await Axios.post("/workshop-article", body);
              setIsLoading(false);
              history.push("/article/workshop/" + res.data.article._id);
            } catch (error) {
              throw error;
            }
          }}
        >
          Save
        </Button>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  setArticle
};

export default connect(
  null,
  mapDispatchToProps
)(CreateWorkshopArticle);
