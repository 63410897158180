import Axios from "axios";

const SET_TALK_WITH_PRAWATE_ARTICLES = "SET_TALK_WITH_PRAWATE_ARTICLES";
const SET_TALK_WITH_PRAWATE_ARTICLE = "SET_TALK_WITH_PRAWATE_ARTICLE";
const ADD_TALK_WITH_PRAWATE_ARTICLE = "ADD_TALK_WITH_PRAWATE_ARTICLE";

const SET_ACTIVE_TALK_WITH_PRAWATE_ARTICLE =
  "SET_ACTIVE_TALK_WITH_PRAWATE_ARTICLE";

export const fetchTalkWithPrawateArticles = () => async dispatch => {
  try {
    const {
      data: { articles }
    } = await Axios.get("/talk-with-prawate");
    dispatch(setTalkWithPrawateArticles(articles));
  } catch (error) {
    return error;
  }
};

export const postTalkWithPrawateArticle = body => async dispatch => {
  try {
    const {
      data: { article }
    } = await Axios.post("/talk-with-prawate", body);
    dispatch(addTalkWithPrawateArticle(article));
  } catch (error) {
    return error;
  }
};

export const patchTalkWithPrawateArticle = (body, _id) => async dispatch => {
  try {
    delete body.src_path;
    const {
      data: { article }
    } = await Axios.patch(`/talk-with-prawate/${_id}`, body);
    console.log(_id, "_id in dispatch");
    dispatch(setTalkWithPrawateArticle(article, _id));
  } catch (error) {
    return error;
  }
};

export const setActiveArticle = _id => ({
  type: SET_ACTIVE_TALK_WITH_PRAWATE_ARTICLE,
  payload: _id
});

export const setTalkWithPrawateArticle = (article, _id) => ({
  type: SET_TALK_WITH_PRAWATE_ARTICLE,
  payload: { article, _id }
});

export const addTalkWithPrawateArticle = article => ({
  type: ADD_TALK_WITH_PRAWATE_ARTICLE,
  payload: article
});
export const setTalkWithPrawateArticles = articles => ({
  type: SET_TALK_WITH_PRAWATE_ARTICLES,
  payload: articles
});

const initialState = {
  articles: [],
  activeArticle: {}
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_ACTIVE_TALK_WITH_PRAWATE_ARTICLE: {
      const index = state.articles.findIndex(a => a._id === payload);
      if (index === -1) return state;
      return {
        ...state,
        activeArticle: state.articles[index]
      };
    }
    case SET_TALK_WITH_PRAWATE_ARTICLES: {
      return {
        ...state,
        articles: payload
      };
    }
    case ADD_TALK_WITH_PRAWATE_ARTICLE: {
      return {
        ...state,
        articles: [...state.articles, payload]
      };
    }
    case SET_TALK_WITH_PRAWATE_ARTICLE: {
      const { article, _id } = payload;
      const index = state.articles.findIndex(a => a._id === _id);
      if (index === -1) return state;
      let updatedArticles = [...state.articles];
      updatedArticles[index] = article;
      return {
        ...state,
        articles: updatedArticles,
        activeArticle: updatedArticles[index]
      };
    }
    default:
      return state;
  }
};

// router.route("/talk-with-prawate/:_id").patch(editTalkWithPrawateArticleById);
// router
//   .route("/talk-with-prawate")
//   .get(getAllTalkWithPrawateArticles)
//   .post(createTalkWithPrawateArticle);
