import React from "react";
import { Switch, Route } from "react-router-dom";

import CreateOnlineArticle from "../articles/CreateOnlineArticle";
import CreateWorkshopArticle from "../articles/CreateWorkshopArticle";
import EditOnlineArticle from "../articles/EditOnlineArticle";
import EditWorkshopArticle from "../articles/EditWorkshopArticle";
import OnlineArticlePreview from "../articles/OnlineArticlePreview";
import WorkshopArticlePreview from "../articles/WorkshopArticlePreview";

const ArticleRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/article/online/:_id/edit"
        component={EditOnlineArticle}
      />
      <Route
        exact
        path="/article/workshop/:_id/edit"
        component={EditWorkshopArticle}
      />
      <Route
        exact
        path="/article/online/:_id"
        component={OnlineArticlePreview}
      />
      <Route
        exact
        path="/article/workshop/:_id"
        component={WorkshopArticlePreview}
      />
      <Route
        exact
        path="/article/online-editor"
        component={CreateOnlineArticle}
      />
      <Route
        exact
        path="/article/workshop-editor"
        component={CreateWorkshopArticle}
      />
    </Switch>
  );
};

export default ArticleRoutes;
