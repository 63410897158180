import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Axios from "axios";
import { Button, Switch, Row } from "antd";
import UploadFileButton from "../components/UploadFileButton";

const WorkshopArticlePreview = ({
  activeArticle: { html, title, isPublished },
  match,
  history
}) => {
  const [publication, setPublication] = useState(isPublished);
  useEffect(() => {
    document.getElementById("article_preview").innerHTML = html;
  }, [html]);
  return (
    <React.Fragment>
      <Row
        justify="space-between"
        type="flex"
        className="mb-3 bg-white pl-4 pr-4 pt-2 pb-2"
      >
        <h3 className="title is-3 mt-3">WorkshopArticlePreview : {title} </h3>
        <div
          className="mt-3"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start"
          }}
        >
          <Button
            type="primary"
            onClick={() =>
              history.push("/article/workshop/" + match.params._id + "/edit")
            }
          >
            Edit
          </Button>
        </div>
      </Row>
      <div
        id="article_preview"
        style={{
          maxWidth: 1000,
          margin: "0 auto",
          // backgroundColor: "white",
          fontSize: 21
          // textAlign: "center"
        }}
      />
      <div
        style={{
          margin: "0 auto"
        }}
      >
        <UploadFileButton
          label="Workshop Picture"
          _id={match.params._id}
          model="workshop"
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center"
        }}
      >
        <Switch
          checked={publication}
          style={{ marginRight: 24, marginTop: 4 }}
          onChange={() => setPublication(!publication)}
        />
        <Button
          type="primary"
          onClick={async () => {
            await Axios.put(`/publish-workshop-article/${match.params._id}`, {
              isPublished: publication
            });
            history.push("/articles");
          }}
        >
          Save publication
        </Button>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  activeArticle: state.articles.activeArticle
});

export default connect(mapStateToProps)(WorkshopArticlePreview);
