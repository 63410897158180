import { setAuthToken, removeAuthToken } from "../common/auth";
import Axios from "axios";

const SET_AUTHENTICATION = "SET_AUTHENTICATION";

export const logIn = (credentials, history) => async dispatch => {
  try {
    const {
      data: { token }
    } = await Axios.post("/login", credentials);
    setAuthToken(token);
    dispatch(setAuthentication(true));
    history.push("/courses");
  } catch (error) {
    console.log(error);
    dispatch(setAuthentication(false));
  }
};

export const logOut = history => dispatch => {
  dispatch(setAuthentication(false));
  removeAuthToken();
  history.push("/");
};

export const setAuthentication = bool => {
  return {
    type: SET_AUTHENTICATION,
    payload: bool
  };
};

const initialState = {
  isAuthenticate: false
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  if (type === SET_AUTHENTICATION) {
    return { ...state, isAuthenticate: payload };
  }
  return state;
};
