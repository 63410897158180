import React from "react";
import AntDesignInputWithFormik from "./AntDesignInputWithFormik";
import ReactPlayer from "react-player";

const FormikVideoInput = ({ field, ...props }) => (
  <React.Fragment>
    <AntDesignInputWithFormik field={field} {...props} />
    {field.value && (
      <ReactPlayer
        url={field.value}
        controls={true}
        width={384}
        height={192}
        style={{ marginBottom: 16, marginLeft: "auto", marginRight: "auto" }}
      />
    )}
  </React.Fragment>
);

export default FormikVideoInput;
