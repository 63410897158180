import React from "react";
import { Form, Field } from "formik";

import AntDesignInputWithFormik from "../components/AntDesignInputWithFormik";

const AddChapterForm = () => (
  <Form>
    <Field
      name="title"
      component={AntDesignInputWithFormik}
      placeholder="Title"
      label="Title"
    />
    <Field
      name="order"
      component={AntDesignInputWithFormik}
      placeholder="Order"
      label="Order"
      type="number"
    />
  </Form>
);

export default AddChapterForm;
