import React, { useEffect } from "react";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Button } from "antd";
import { Editor } from "react-draft-wysiwyg";
import { connect } from "react-redux";
import { setTimelineArticle } from "./ducks";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import useReactEditor from "../hooks/useReactEditor";

const UpdateTimeline = ({ history, article, setTimelineArticle }) => {
  const {
    editorState,
    onEditorStateChange,
    uploadImageCallBack
  } = useReactEditor(null);

  useEffect(() => {
    const loadHTMLToDraft = () => {
      const { html } = article;
      if (!html) return;
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        onEditorStateChange(EditorState.createWithContent(contentState));
      }
    };
    loadHTMLToDraft();
    //eslint-disable-next-line
  }, []);
  const _id = article._id;
  return (
    <React.Fragment>
      <div className="bg-white pl-4 pr-4 pt-2 pb-2">
        <h2 style={{ marginBottom: 0 }}>Timeline</h2>
      </div>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          image: {
            uploadCallback: uploadImageCallBack,
            alt: { present: true, mandatory: false }
          }
        }}
      />
      <div style={{ display: "flex", justifyContent: "center", marginTop: 12 }}>
        <Button
          type="primary"
          size="large"
          style={{ minWidth: 120 }}
          onClick={() => {
            const html = draftToHtml(
              convertToRaw(editorState.getCurrentContent())
            );
            setTimelineArticle({ ...article, html });
            history.push("/timeline/" + _id + "/preview");
          }}
        >
          Next
        </Button>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  article: state.timeline.article
});

const mapDispatchToProps = {
  setTimelineArticle
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateTimeline);
