import Axios from "axios";

const SET_TAGS = "SET_TAGS";
const SET_TAG = "SET_TAG";
const DELETE_TAG = "DELETE_TAG";
const ADD_TAG = "ADD_TAG";

export const fetchTags = () => async dispatch => {
  try {
    const {
      data: { tags }
    } = await Axios.get("/tag");
    dispatch(setTags(tags));
  } catch (error) {
    return error;
  }
};

export const setTags = tags => ({
  type: SET_TAGS,
  payload: tags
});

export const postTag = body => async dispatch => {
  try {
    const {
      data: { tag }
    } = await Axios.post(`/tag`, body);
    dispatch(addTag(tag));
  } catch (error) {
    return error;
  }
};

export const addTag = tag => ({
  type: ADD_TAG,
  payload: tag
});

export const editTagById = (_id, body) => async dispatch => {
  try {
    const {
      data: { tag }
    } = await Axios.patch(`/tag/${_id}`, body);
    dispatch(setTag(tag, _id));
  } catch (error) {
    return error;
  }
};

export const setTag = (tag, _id) => ({
  type: SET_TAG,
  payload: { tag, _id }
});

export const deleteTagById = _id => async dispatch => {
  try {
    await Axios.delete(`/tag/${_id}`);
    dispatch(deleteTag(_id));
  } catch (error) {
    return error;
  }
};

export const deleteTag = _id => ({
  type: DELETE_TAG,
  payload: _id
});

const initialState = {
  tags: []
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_TAGS: {
      return {
        ...state,
        tags: payload
      };
    }
    case ADD_TAG: {
      return {
        ...state,
        tags: [...state.tags, payload]
      };
    }
    case SET_TAG: {
      const { tag, _id } = payload;
      const index = state.tags.findIndex(t => t._id === _id);
      if (index === -1) return state;
      let updatedTags = [...state.tags];
      updatedTags[index] = tag;
      return {
        ...state,
        tags: updatedTags
      };
    }
    case DELETE_TAG: {
      const _id = payload;
      const index = state.tags.findIndex(t => t._id === _id);
      if (index === -1) return state;
      let updatedTags = [...state.tags];
      updatedTags.splice(index, 1);
      return {
        ...state,
        tags: updatedTags
      };
    }
    default:
      return state;
  }
};
