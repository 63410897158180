import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Button, Row } from "antd";
import { editChapter } from "./ducks";

const ChapterArticlePreview = ({ chapter, match, history, editChapter }) => {
  useEffect(() => {
    if (!chapter) return history.push("/");
    if (!chapter.article)
      return history.push("/article/chapter/" + match.params._id);
    document.getElementById("article_preview").innerHTML = chapter.article;
    //eslint-disable-next-line
  }, [chapter]);
  return (
    <React.Fragment>
      <Row
        justify="space-between"
        type="flex"
        className="mb-3 bg-white pl-4 pr-4 pt-2 pb-2"
      >
        <h3 className="title is-3 mt-3">
          Q/A Ch.{chapter.order}: {chapter.title}{" "}
        </h3>
        <div
          className="mt-3"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start"
          }}
        >
          <Button
            type="primary"
            onClick={() =>
              history.push("/chapter/" + match.params._id + "/article")
            }
          >
            Edit
          </Button>
        </div>
      </Row>
      <div
        id="article_preview"
        style={{
          maxWidth: 1000,
          margin: "0 auto",
          // backgroundColor: "white",
          fontSize: 21
          // textAlign: "center"
        }}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "center"
        }}
      >
        <Button
          type="primary"
          onClick={async () => {
            await editChapter(chapter, match.params._id, () =>
              history.push("/courses/" + chapter.course_id + "/display")
            );
          }}
          style={{ marginBottom: 12 }}
        >
          Save
        </Button>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  chapter: state.chapters.chapter
});
export default connect(
  mapStateToProps,
  { editChapter }
)(ChapterArticlePreview);
