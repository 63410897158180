import { Row, Input, Col, Button, Table, Modal, Form, Icon } from 'antd';
import React, { useState } from 'react';
import Axios from 'axios';
import { showRequestErrorModal } from '../common/Error';

const Users = () => {
    const [users, setUsers] = useState([])
    const [editing_user, setEditingUser] = useState(null)
    const [is_modal_visible, setIsModalVisible] = useState(false);
    const columns = [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'First name',
            dataIndex: 'first_name',
            key: 'first_name'
        },
        {
            title: 'Mobile',
            dataIndex: 'mobile',
            key: 'mobile'
        },
        {
            title: 'Action',
            dataIndex: '_id',
            key: 'action',
            render: (_id, record) => {
                return (
                    <Button type="primary" onClick={() => {
                        setEditingUser({ _id, current_email: record.email })
                        setIsModalVisible(true)
                    }}>
                        Edit
                    </Button>
                )

            }
        }

    ];
    async function searchUserByEmail(query_string) {
        try {
            const response = await Axios.get("/user/search", {
                params: {
                    query_string: query_string
                }
            })
            setUsers(response.data ? response.data.users : []);
        } catch (error) {
            showRequestErrorModal(error)
        }
    }
    return (
        <div className="pl-4 pr-4">
            <Row
                justify="space-between"
                type="flex"
                className="mb-3 bg-white pl-4 pr-4 pt-2 pb-2"
            >
                <Col span={6}>
                    <h3 className="is-3 mt-3"> Users </h3>
                </Col>
                <Col span={6}>
                    <Input.Search
                        className="mt-3"
                        placeholder="Email"
                        enterButton="Search"
                        size="large"
                        onSearch={async value => { searchUserByEmail(value); }}
                    />
                </Col>
            </Row>
            <Table dataSource={users} columns={columns} rowKey="_id" />
            <EditUserEmailModal
                is_visible={is_modal_visible}
                closeModal={() => {
                    setIsModalVisible(false);
                    setEditingUser(null)
                }}
                successCallback={({ email, _id }) => {
                    //eslint-disable-next-line
                    const index = users.findIndex(user => user._id == _id)
                    if (index !== -1) {
                        let usersClone = [...users];
                        usersClone[index].email = email;
                        setUsers(usersClone);
                    }
                    setIsModalVisible(false);
                    setEditingUser(null)
                }}
                editing_user={editing_user}
            />

        </div>
    )
}

export default Users;

const EditUserEmailModal = (props) => {
    const { is_visible, closeModal, successCallback, editing_user } = props;
    const [email, setEmail] = useState(null)
    return (
        <Modal
            visible={is_visible}
            title="Edit user's email"
            onOk={async () => {
                try {
                    await Axios.patch(`/user/${editing_user._id}`, { email: email })
                    console.log(email, 'email')
                    Modal.success({ title: 'Operation success', content: `User's email changed -> ${email}` })
                    setEmail(null);
                    successCallback({ email, _id: editing_user._id })
                } catch (error) {
                    showRequestErrorModal(error);
                    setEmail(null)
                    closeModal()
                }

            }}
            onCancel={() => { setEmail(null); closeModal(); }}
        >
            <Form>
                <Form.Item>
                    <Input
                        value={editing_user ? editing_user.current_email : ''}
                        prefix={<Icon type="email" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="Current email"
                        disabled={true}
                    />
                </Form.Item>
                <Form.Item>
                    <Input
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        prefix={<Icon type="email" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="New email"
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}

