import { useState } from "react";
import Axios from "axios";

const useReactEditor = initialValue => {
  const [editorState, setEditorState] = useState(initialValue);
  const onEditorStateChange = editorState => {
    setEditorState(editorState);
  };
  const uploadImageCallBack = file => {
    return new Promise(async (resolve, reject) => {
      const data = new FormData();
      data.append("file", file);
      try {
        const res = await Axios.post("/upload/article-image", data);
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  };
  return {
    editorState,
    onEditorStateChange,
    uploadImageCallBack
  };
};

export default useReactEditor;
