import React, { useEffect, useState } from "react";
import { Table, Button, Modal } from "antd";
import Axios from "axios";
const confirm = Modal.confirm;

const Transactions = () => {
  const [purchases, setPurchases] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  function removeTransactionByIds(user_id, course_id) {
    const purchasesClone = [...purchases];
    const index = purchasesClone.findIndex(
      record =>
        user_id === record.user_id._id && course_id === record.course_id._id
    );
    purchasesClone.splice(index, 1);
    setPurchases(purchasesClone);
  }
  function showConfirm(user_id, course_id, email, title) {
    confirm({
      title: "Do you want to approve this transaction?",
      content:
        "Approving this transaction will grant this user full access to their registered course.",
      async onOk() {
        setIsLoading(true);
        await Axios.post("/complete-purchase", {
          user_id,
          course_id,
          email,
          title
        });
        removeTransactionByIds(user_id, course_id);
        setIsLoading(false);
      }
    });
  }
  function showDeleteConfirm(user_id, course_id) {
    confirm({
      title: "Do you want to remove this transaction?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        setIsLoading(true);
        await Axios.delete("/purchase", {
          data: { user_id, course_id }
        });
        removeTransactionByIds(user_id, course_id);
        setIsLoading(false);
      }
    });
  }
  const columns = [
    {
      title: "Name",
      dataIndex: "user_id.first_name"
    },
    { title: "email", dataIndex: "user_id.email" },
    { title: "Course", dataIndex: "course_id.title" },
    { title: "Since", dataIndex: "start_date" },
    {
      title: "Grant access",
      key: "action",
      render: (text, record) => {
        return (
          <Button
            type="primary"
            onClick={() =>
              showConfirm(
                record.user_id._id,
                record.course_id._id,
                record.user_id.email,
                record.course_id.title
              )
            }
          >
            Grant access
          </Button>
        );
      }
    },
    {
      title: "Remove record",
      key: "remove",
      render: (text, record) => {
        return (
          <Button
            type="danger"
            onClick={() =>
              showDeleteConfirm(record.user_id._id, record.course_id._id)
            }
          >
            Remove transaction
          </Button>
        );
      }
    }
  ];
  const fetchPendingPurchases = async () => {
    setIsLoading(true);
    const res = await Axios.get("/all-pending-purchases");
    const {
      data: { purchases }
    } = res;
    setIsLoading(false);
    setPurchases(purchases);
  };

  useEffect(() => {
    fetchPendingPurchases();
  }, []);
  return (
    <React.Fragment>
      <div className="mb-3 bg-white pl-4 pr-4 pt-2 pb-2">
        <h1> Pending Transactions </h1>
      </div>
      <Table
        columns={columns}
        dataSource={purchases}
        rowKey={record => record._id}
        style={{ backgroundColor: "white" }}
      />
      {isLoading && <div className="loading">Loading&#8230;</div>}
    </React.Fragment>
  );
};

export default Transactions;
