import React from "react";
import { Row, Col, Upload, Button, Icon, message } from "antd";
import { END_POINT } from "../common/httpconstants";

const UploadFileButton = ({ label, model, _id, size }) => {
  let colSize = {
    left: 4,
    right: 20
  };
  if (size === "small") {
    colSize = {
      left: 6,
      right: 18
    };
  }
  const uploadConfigs = {
    name: "file",
    action: `${END_POINT}/upload/${model}/${_id}`,
    headers: {
      authorization: localStorage.getItem("token")
    },
    onChange(info) {
      //info.file.response.link!
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col span={colSize.left}>
          <p style={{ marginTop: 6, marginBottom: 0, textAlign: "right" }}>
            <strong> {label} </strong>
          </p>
        </Col>
        <Col span={colSize.right}>
          <Upload {...uploadConfigs}>
            <Button style={{ marginLeft: 8 }}>
              <Icon type="upload" /> Click to Upload
            </Button>
          </Upload>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default UploadFileButton;
