import React from "react";
import PropTypes from "prop-types";
import { List, Button } from "antd";
import { Link } from "react-router-dom";

const WorkshopArticleList = ({
  workshop_type,
  workshop_list,
  history,
  setArticle,
}) => {
  return (
    <div className="bg-white" style={{ marginTop: "1rem" }}>
      <List
        header={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h4> {workshop_type} </h4>
            <Button
              type="primary"
              onClick={() => history.push("/article/workshop-editor")}
            >
              New
            </Button>
          </div>
        }
        bordered
        dataSource={workshop_list}
        renderItem={({ title, _id, html, isPublished, src_path, _type }) => (
          <List.Item key={_id}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div>
                <img
                  src={src_path}
                  style={{ maxWidth: 200, minWidth: 200 }}
                  alt="workshop"
                />
              </div>
              <Link
                to={`/article/workshop/${_id}`}
                onClick={() =>
                  setArticle({ title, html, _id, isPublished, _type })
                }
                style={{ marginLeft: 12 }}
              >
                {title}
              </Link>
            </div>
          </List.Item>
        )}
      />
    </div>
  );
};

WorkshopArticleList.propTypes = {
  workshop_type: PropTypes.string,
  workshop_list: PropTypes.array,
  history: PropTypes.object,
  setArticle: PropTypes.func,
};

export default WorkshopArticleList;
