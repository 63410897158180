import Axios from "axios";

const SET_LESSONS = "SET_LESSONS";
const ADD_LESSON = "ADD_LESSON";
const SET_LESSON = "SET_LESSON";
const SET_ACTIVE_LESSON = "SET ACTIVE LESSON";

export const fetchLessons = course_id => async dispatch => {
  try {
    const {
      data: { lessons }
    } = await Axios.get(`/lessons/${course_id}`);
    dispatch(setLessons(lessons));
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getUrlById = _id => async dispatch => {
  try {
    const {
      data: { lesson }
    } = await Axios.get(`/lesson-url/${_id}`);
    dispatch(setLesson(lesson, _id));
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const setLesson = (lesson, _id) => {
  return {
    type: SET_LESSON,
    payload: { lesson, _id }
  };
};

export const postLesson = body => async dispatch => {
  try {
    const {
      data: { lesson }
    } = await Axios.post("/add-lesson", body);
    dispatch(addLesson(lesson));
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const editLessonById = (body, _id, callback) => async dispatch => {
  try {
    const {
      data: { lesson }
    } = await Axios.put(`/lesson/${_id}`, body);
    callback();
    dispatch(setLesson(lesson, _id));
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const setActiveLessonById = _id => {
  return {
    type: SET_ACTIVE_LESSON,
    payload: _id
  };
};

export const addLesson = lesson => {
  return {
    type: ADD_LESSON,
    payload: lesson
  };
};
export const setLessons = lessons => {
  return {
    type: SET_LESSONS,
    payload: lessons
  };
};

const initialState = {
  lessons: [],
  activeLesson: {}
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_LESSONS: {
      return {
        ...state,
        lessons: payload
      };
    }
    case SET_LESSON: {
      const { _id, lesson } = payload;
      const index = state.lessons.findIndex(lesson => lesson._id === _id);
      let updatedLessons = [...state.lessons];
      updatedLessons[index] = lesson;
      return {
        ...state,
        lessons: updatedLessons,
        activeLesson: updatedLessons[index]
      };
    }
    case SET_ACTIVE_LESSON: {
      const activeLesson = state.lessons.find(lesson => lesson._id === payload);
      return {
        ...state,
        activeLesson
      };
    }
    case ADD_LESSON: {
      return {
        ...state,
        lessons: [...state.lessons, payload]
      };
    }
    default:
      return state;
  }
};
